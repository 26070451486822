'use client';

import { useQuery, type ApolloError } from '@apollo/client';
import merge from 'lodash/merge';
import orderBy from 'lodash/orderBy';
import { useCallback, useEffect, useRef, useMemo, useState } from 'react';

import QUERY_SUBSIDIARY_CARRIER_LIST, {
  type SubsidiaryCarrierListQueryData,
  type SubsidiaryCarrierListQueryVariables,
  type CarrierItem,
} from '~/apollo/operations/queries/QuerySubsidiaryCarrierList';
import SUBSCRIBE_TO_CARRIER, {
  type CarrierSubscriptionData,
  type CarrierSubscriptionVariables,
} from '~/apollo/operations/subscriptions/SubscribeToCarrier';
import SUBSCRIBE_TO_SUBSIDIARY_MEASUREMENT, {
  type SubsidiaryMeasurementSubscriptionData,
  type SubsidiaryMeasurementSubscriptionVariables,
} from '~/apollo/operations/subscriptions/SubscribeToSubsidiaryMeasurement';
import { DEFAULT_BASE_DEVICE_CONFIGURATION } from '~/config/defaults';
import useCompanyFeatures, { type CompanyFeatures } from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';
import type { AgentMergedAlarmConfiguration } from '~/types/agent';
import {
  SENSOR_NAME_QUERY,
  SENSOR_NAME_VARIABLE,
  SENSOR_STATUS_QUERY,
  SENSOR_STATUS_VARIABLE,
} from '~/types/sensor';
import { STREAM_STATE } from '~/types/videoStream';
import logger from '~/utils/logger';
import notification from '~/utils/notification';

const DEFAULT_ALARM_CONFIGURATION: AgentMergedAlarmConfiguration = {
  __typename: 'AlarmConfiguration',
  activated: false,
  activate_video: true,
};

const DEFAULT_CARRIER_ITEM: CarrierItem = {
  __typename: 'Carrier_Cognito',
  id: '',
  name: '',
  email: '',
  attributes: [],
  video_stream: null,
  requested_video_stream_status: null,
  device: {
    __typename: 'Device',
    name: '',
    connected: { __typename: 'MeasurementConnection', items: [] },
    gps: { __typename: 'MeasurementConnection', items: [] },
    heartRate: { __typename: 'MeasurementConnection', items: [] },
    heartRateStatus: { __typename: 'MeasurementConnection', items: [] },
    bodyMultiSensorV1: { __typename: 'MeasurementConnection', items: [] },
    bodyMultiSensorV1Status: { __typename: 'MeasurementConnection', items: [] },
    bodyTemperature: { __typename: 'MeasurementConnection', items: [] },
    bodyTemperatureStatus: { __typename: 'MeasurementConnection', items: [] },
    gas: { __typename: 'MeasurementConnection', items: [] },
    gasStatus: { __typename: 'MeasurementConnection', items: [] },
    radiation: { __typename: 'MeasurementConnection', items: [] },
    radiationStatus: { __typename: 'MeasurementConnection', items: [] },
    brainStop: { __typename: 'MeasurementConnection', items: [] },
    connected_history: { __typename: 'MeasurementConnection', items: [] },
    traakFrontStatus: { __typename: 'MeasurementConnection', items: [] },
    traakBackStatus: { __typename: 'MeasurementConnection', items: [] },
    emergencyStatus: { __typename: 'MeasurementConnection', items: [] },
    battery: { __typename: 'MeasurementConnection', items: [] },
    batteryStatus: { __typename: 'MeasurementConnection', items: [] },
    lteSignalStrength: { __typename: 'MeasurementConnection', items: [] },
    lteSignalStrengthStatus: { __typename: 'MeasurementConnection', items: [] },
    oxygenSupply: { __typename: 'MeasurementConnection', items: [] },
    oxygenSupplyStatus: { __typename: 'MeasurementConnection', items: [] },
  },
  mergeConfigDevice_base: {
    __typename: 'DeviceConfiguration',
    wbr_orientation: DEFAULT_BASE_DEVICE_CONFIGURATION.wbr_orientation,
    embedded_sound: DEFAULT_BASE_DEVICE_CONFIGURATION.embedded_sound,
  },
  mergeConfigAlarm_emergency: DEFAULT_ALARM_CONFIGURATION,
  mergeConfigAlarm_fall: DEFAULT_ALARM_CONFIGURATION,
  mergeConfigAlarm_attack: DEFAULT_ALARM_CONFIGURATION,
  mergeConfigAlarm_traak_front: DEFAULT_ALARM_CONFIGURATION,
  mergeConfigAlarm_traak_back: DEFAULT_ALARM_CONFIGURATION,
  mergeConfigAlarm_stress: DEFAULT_ALARM_CONFIGURATION,
  mergeConfigAlarm_abnormal_stops: DEFAULT_ALARM_CONFIGURATION,
  mergeConfigAlarm_long_stops: DEFAULT_ALARM_CONFIGURATION,
  mergeConfigAlarm_gas_danger: DEFAULT_ALARM_CONFIGURATION,
  mergeConfigAlarm_gas_high: DEFAULT_ALARM_CONFIGURATION,
};

const DEFAULT_PREVIOUS_RESULT: SubsidiaryCarrierListQueryData = {
  subsidiary: {
    __typename: 'Subsidiary',
    id: 'DEFAULT_SUBSIDIARY_ID',
    carriers: {
      __typename: 'CarrierConnection',
      items: [],
      nextToken: null,
    },
  },
};

export default function useQueryWithSubscriptionSubsidiaryCarrierList({
  subsidiaryID,
  skip = false,
}: {
  subsidiaryID: string;
  skip: boolean;
}): {
  subsidiaryCarrierList: CarrierItem[];
  isLoading: boolean;
  error?: ApolloError;
  refetch: () => Promise<void>;
} {
  const { companyFeatures } = useCompanyFeatures();
  const { subscribeToMore, fetchMore, client, loading, error, data, refetch } = useQuery<
    SubsidiaryCarrierListQueryData,
    SubsidiaryCarrierListQueryVariables
  >(QUERY_SUBSIDIARY_CARRIER_LIST, {
    variables: { subsidiaryID },
    fetchPolicy: 'no-cache',
    skip,
  });
  const subscriptionsRef = useRef<(() => void)[]>([]);
  const subscribedFeaturesRef = useRef<Set<keyof CompanyFeatures>>(new Set());

  const [subsidiaryCarrierList, setSubsidiaryCarrierList] = useState<CarrierItem[]>([]);

  useEffect(() => {
    setSubsidiaryCarrierList(data?.subsidiary?.carriers?.items || []);
  }, [data]);

  const subscribeToMoreForCarrier = useCallback(
    (subsidiaryId: string) =>
      subscribeToMore<CarrierSubscriptionData, CarrierSubscriptionVariables>({
        document: SUBSCRIBE_TO_CARRIER,
        variables: { subsidiaryID: subsidiaryId },
        updateQuery: (previousResult, { subscriptionData }) => {
          const updatedItem = subscriptionData?.data?.carrier?.carrier;
          if (!updatedItem) {
            return DEFAULT_PREVIOUS_RESULT || previousResult;
          }
          logger.log('useQueryWithSubscriptionSubsidiaryCarrierList: carrier update', {
            carrierId: updatedItem.id,
            subsidiaryId,
            subscriptionData,
          });
          setSubsidiaryCarrierList((prevList) => {
            const isExisting = prevList?.some((carrierItem) => carrierItem.id === updatedItem.id);
            if (isExisting) {
              return prevList.map((carrierItem) =>
                carrierItem.id === updatedItem.id
                  ? {
                      ...carrierItem,
                      name: updatedItem.name,
                      attributes: updatedItem.attributes,
                      video_stream: {
                        ivs_stream_state:
                          updatedItem.video_stream?.ivs_stream_state ||
                          carrierItem.video_stream?.ivs_stream_state ||
                          STREAM_STATE.END,
                      },
                    }
                  : carrierItem,
              );
            }

            return [merge({}, DEFAULT_CARRIER_ITEM, updatedItem), ...prevList];
          });
          return DEFAULT_PREVIOUS_RESULT || previousResult;
        },
      }),
    [subscribeToMore],
  );

  const subscribeToMoreForSensorType = useCallback(
    (
      subsidiaryId: string,
      sensorQueryName: SENSOR_NAME_QUERY | SENSOR_STATUS_QUERY,
      sensorName: SENSOR_NAME_VARIABLE | SENSOR_STATUS_VARIABLE,
    ) =>
      subscribeToMore<
        SubsidiaryMeasurementSubscriptionData,
        SubsidiaryMeasurementSubscriptionVariables
      >({
        document: SUBSCRIBE_TO_SUBSIDIARY_MEASUREMENT,
        variables: { subsidiaryID: subsidiaryId, type: sensorQueryName },
        updateQuery: (previousResult, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return DEFAULT_PREVIOUS_RESULT || previousResult;
          }
          const carrierId = subscriptionData.data.measurements?.carrier_id;
          const comingMeasurements = orderBy(
            subscriptionData.data.measurements?.measurements || [],
            'timestamp',
            'desc',
          );
          logger.log('useQueryWithSubscriptionSubsidiaryCarrierList: sensor update', {
            sensorName,
            carrierId,
            subsidiaryId,
            comingMeasurements,
          });
          setSubsidiaryCarrierList((prevList) =>
            prevList.map((carrierItem) => {
              if (carrierItem.id === carrierId) {
                const updatedItem: CarrierItem = {
                  ...carrierItem,
                  device: {
                    ...carrierItem.device,
                    [sensorName]: {
                      ...carrierItem.device?.[sensorName],
                      items: comingMeasurements,
                    },
                  },
                };
                if (sensorName === SENSOR_NAME_VARIABLE.connected) {
                  // Sort by timestamp ascending where the first item is the oldest
                  const sortedConnectedHistory = orderBy(
                    [
                      ...(carrierItem.device?.[SENSOR_NAME_VARIABLE.connected_history]?.items ||
                        []),
                      ...comingMeasurements,
                    ],
                    'timestamp',
                    'asc',
                  );
                  const latestConnected = sortedConnectedHistory.at(-1);
                  updatedItem.device[SENSOR_NAME_VARIABLE.connected_history] = {
                    items: sortedConnectedHistory,
                  };
                  updatedItem.device[SENSOR_NAME_VARIABLE.connected] = {
                    items: latestConnected ? [latestConnected] : comingMeasurements,
                  };
                }
                return updatedItem;
              }
              return merge({}, DEFAULT_CARRIER_ITEM, carrierItem);
            }),
          );
          return DEFAULT_PREVIOUS_RESULT || previousResult;
        },
      }),
    [subscribeToMore],
  );

  useEffect(() => {
    if (skip || loading || !subsidiaryID || !data?.subsidiary?.carriers?.nextToken) {
      return;
    }
    fetchMore({
      variables: { nextToken: data.subsidiary.carriers.nextToken },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        logger.log('useQueryWithSubscriptionSubsidiaryCarrierList: fetchMore subsidiary carriers', {
          subsidiaryID,
        });
        const result: SubsidiaryCarrierListQueryData = {
          ...previousResult,
          subsidiary: {
            ...previousResult.subsidiary,
            id: previousResult.subsidiary?.id || subsidiaryID,
            carriers: {
              ...previousResult.subsidiary?.carriers,
              items: [
                ...(previousResult.subsidiary?.carriers?.items || []),
                ...(fetchMoreResult.subsidiary?.carriers?.items || []),
              ],
              nextToken: fetchMoreResult.subsidiary?.carriers?.nextToken || null,
            },
          },
        };
        return result || DEFAULT_PREVIOUS_RESULT || previousResult;
      },
    }).catch(() => {
      notification.error({
        message: i18n.t('general.notifications.fetchDataErrorTitle'),
        description: i18n.t('general.notifications.fetchDataErrorDescription'),
      });
    });
  }, [fetchMore, skip, loading, subsidiaryID, data?.subsidiary?.carriers?.nextToken]);

  useEffect(() => {
    if (subsidiaryID) {
      logger.log('useQueryWithSubscriptionSubsidiaryCarrierList: subscribing', {
        subsidiaryID,
        date: new Date().toISOString(),
        subscriptionsLength: subscriptionsRef.current?.length,
      });
      subscriptionsRef.current = [
        subscribeToMoreForCarrier(subsidiaryID),
        subscribeToMoreForSensorType(
          subsidiaryID,
          SENSOR_STATUS_QUERY.emergency_status,
          SENSOR_STATUS_VARIABLE.emergencyStatus,
        ),
        subscribeToMoreForSensorType(subsidiaryID, SENSOR_NAME_QUERY.gps, SENSOR_NAME_VARIABLE.gps),
        subscribeToMoreForSensorType(
          subsidiaryID,
          SENSOR_NAME_QUERY.brain_stop,
          SENSOR_NAME_VARIABLE.brainStop,
        ),
        subscribeToMoreForSensorType(
          subsidiaryID,
          SENSOR_NAME_QUERY.connected,
          SENSOR_NAME_VARIABLE.connected,
        ),
        subscribeToMoreForSensorType(
          subsidiaryID,
          SENSOR_NAME_QUERY.battery,
          SENSOR_NAME_VARIABLE.battery,
        ),
        subscribeToMoreForSensorType(
          subsidiaryID,
          SENSOR_STATUS_QUERY.battery_status,
          SENSOR_STATUS_VARIABLE.batteryStatus,
        ),
        subscribeToMoreForSensorType(
          subsidiaryID,
          SENSOR_NAME_QUERY.lte_signal_strength,
          SENSOR_NAME_VARIABLE.lteSignalStrength,
        ),
        subscribeToMoreForSensorType(
          subsidiaryID,
          SENSOR_STATUS_QUERY.lte_signal_strength_status,
          SENSOR_STATUS_VARIABLE.lteSignalStrengthStatus,
        ),
      ];
    }

    return () => {
      logger.log('useQueryWithSubscriptionSubsidiaryCarrierList: unsubscribing', {
        subsidiaryID,
        date: new Date().toISOString(),
        subscriptionsLength: subscriptionsRef.current?.length,
      });
      subscriptionsRef.current.forEach((fn) => fn());
      subscriptionsRef.current = [];
      subscribedFeaturesRef.current = new Set();
    };
  }, [subsidiaryID, client, subscribeToMoreForCarrier, subscribeToMoreForSensorType]);

  // needs to be separate from non-feature subscriptions in order to not cause
  // fast successive subscribing and unsubscribing (because features get loaded
  // at different time than other dependencies) which produces appsync error
  // https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/509

  useEffect(() => {
    if (subsidiaryID) {
      logger.log('useQueryWithSubscriptionSubsidiaryCarrierList: subscribing to features', {
        subsidiaryID,
        date: new Date().toISOString(),
        subscriptionsLength: subscriptionsRef.current?.length,
        subscribedFeaturesSize: subscribedFeaturesRef.current?.size,
      });
      if (
        companyFeatures.heartRateSensor &&
        !subscribedFeaturesRef.current.has('heartRateSensor')
      ) {
        subscribedFeaturesRef.current.add('heartRateSensor');
        subscriptionsRef.current = [
          ...subscriptionsRef.current,
          subscribeToMoreForSensorType(
            subsidiaryID,
            SENSOR_NAME_QUERY.heart_rate,
            SENSOR_NAME_VARIABLE.heartRate,
          ),
          subscribeToMoreForSensorType(
            subsidiaryID,
            SENSOR_STATUS_QUERY.heart_rate_status,
            SENSOR_STATUS_VARIABLE.heartRateStatus,
          ),
        ];
      }
      if (
        companyFeatures.physiologicalTemperatureSensor &&
        !subscribedFeaturesRef.current.has('physiologicalTemperatureSensor')
      ) {
        subscribedFeaturesRef.current.add('physiologicalTemperatureSensor');
        subscriptionsRef.current = [
          ...subscriptionsRef.current,
          subscribeToMoreForSensorType(
            subsidiaryID,
            SENSOR_NAME_QUERY.body_multi_sensor_v1,
            SENSOR_NAME_VARIABLE.bodyMultiSensorV1,
          ),
          subscribeToMoreForSensorType(
            subsidiaryID,
            SENSOR_STATUS_QUERY.body_multi_sensor_v1_status,
            SENSOR_STATUS_VARIABLE.bodyMultiSensorV1Status,
          ),
        ];
      }
      if (
        companyFeatures.bodyTemperatureSensor &&
        !subscribedFeaturesRef.current.has('bodyTemperatureSensor')
      ) {
        subscribedFeaturesRef.current.add('bodyTemperatureSensor');
        subscriptionsRef.current = [
          ...subscriptionsRef.current,
          subscribeToMoreForSensorType(
            subsidiaryID,
            SENSOR_NAME_QUERY.body_temperature, // deprecated
            SENSOR_NAME_VARIABLE.bodyTemperature, // deprecated
          ),
          subscribeToMoreForSensorType(
            subsidiaryID,
            SENSOR_STATUS_QUERY.body_temperature_status, // deprecated
            SENSOR_STATUS_VARIABLE.bodyTemperatureStatus, // deprecated
          ),
        ];
      }
      if (companyFeatures.gasSensor && !subscribedFeaturesRef.current.has('gasSensor')) {
        subscribedFeaturesRef.current.add('gasSensor');
        subscriptionsRef.current = [
          ...subscriptionsRef.current,
          subscribeToMoreForSensorType(
            subsidiaryID,
            SENSOR_NAME_QUERY.gas,
            SENSOR_NAME_VARIABLE.gas,
          ),
          subscribeToMoreForSensorType(
            subsidiaryID,
            SENSOR_STATUS_QUERY.gas_status,
            SENSOR_STATUS_VARIABLE.gasStatus,
          ),
        ];
      }
      if (
        companyFeatures.radiationSensor &&
        !subscribedFeaturesRef.current.has('radiationSensor')
      ) {
        subscribedFeaturesRef.current.add('radiationSensor');
        subscriptionsRef.current = [
          ...subscriptionsRef.current,
          subscribeToMoreForSensorType(
            subsidiaryID,
            SENSOR_NAME_QUERY.radiation,
            SENSOR_NAME_VARIABLE.radiation,
          ),
          subscribeToMoreForSensorType(
            subsidiaryID,
            SENSOR_STATUS_QUERY.radiation_status,
            SENSOR_STATUS_VARIABLE.radiationStatus,
          ),
        ];
      }
      if (
        companyFeatures.oxygenSupplySensor &&
        !subscribedFeaturesRef.current.has('oxygenSupplySensor')
      ) {
        subscribedFeaturesRef.current.add('oxygenSupplySensor');
        subscriptionsRef.current = [
          ...subscriptionsRef.current,
          subscribeToMoreForSensorType(
            subsidiaryID,
            SENSOR_NAME_QUERY.oxygen_supply,
            SENSOR_NAME_VARIABLE.oxygenSupply,
          ),
          subscribeToMoreForSensorType(
            subsidiaryID,
            SENSOR_STATUS_QUERY.oxygen_supply_status,
            SENSOR_STATUS_VARIABLE.oxygenSupplyStatus,
          ),
        ];
      }
      if (
        companyFeatures.impactDetectionFront &&
        !subscribedFeaturesRef.current.has('impactDetectionFront')
      ) {
        subscribedFeaturesRef.current.add('impactDetectionFront');
        subscriptionsRef.current = [
          ...subscriptionsRef.current,
          subscribeToMoreForSensorType(
            subsidiaryID,
            SENSOR_STATUS_QUERY.traak_front_status,
            SENSOR_STATUS_VARIABLE.traakFrontStatus,
          ),
        ];
      }
      if (
        companyFeatures.impactDetectionBack &&
        !subscribedFeaturesRef.current.has('impactDetectionBack')
      ) {
        subscribedFeaturesRef.current.add('impactDetectionBack');
        subscriptionsRef.current = [
          ...subscriptionsRef.current,
          subscribeToMoreForSensorType(
            subsidiaryID,
            SENSOR_STATUS_QUERY.traak_back_status,
            SENSOR_STATUS_VARIABLE.traakBackStatus,
          ),
        ];
      }
    }
  }, [
    subsidiaryID,
    companyFeatures.heartRateSensor,
    companyFeatures.physiologicalTemperatureSensor,
    companyFeatures.bodyTemperatureSensor, // deprecated
    companyFeatures.gasSensor,
    companyFeatures.radiationSensor,
    companyFeatures.oxygenSupplySensor,
    companyFeatures.impactDetectionFront,
    companyFeatures.impactDetectionBack,
    subscribeToMoreForSensorType,
  ]);

  return useMemo(
    () => ({
      subsidiaryCarrierList,
      isLoading: loading,
      error,
      refetch: async () => {
        await refetch();
      },
    }),
    [subsidiaryCarrierList, loading, error, refetch],
  );
}
